.BankStatementTable {
  box-shadow: inset 0 0 0 1px rgba(0,0,0,.08);
  border-radius: 6px;
  background-color: rgba(0,0,0,.03);
  max-height: 135px;
  overflow: hidden;
  padding: 12px 0;
}

.BankStatementTable.BankStatementTable-Header{
  padding: 0 12px 12px;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;
}

table {
  height: 100%;
  width: 100%;
}

#tranTable {
  color: gray;
}