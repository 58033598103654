.ant-carousel .slick-dots li button {
    /* background: #ff4ef6;
    opacity: 0.4; */
    display: none !important;
}

.ant-carousel .slick-dots li.slick-active button {
    /* opacity: 1;
    background: #ff4ef6; */
    display: none !important;
}

/* .ant-modal-content {
  border-radius: 20px !important;
} */

.ant-modal-mask {
  z-index: 100 !important;
}


.ant-modal-wrap {
  z-index: 200 !important;
}

/* .ant-modal-header {
  border-radius: 20px !important;
} */