

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

.gradient-background {
  background-image:radial-gradient(42% 49.5% at 27% 26%, #f9d7d7 0%, rgba(255, 0, 0, 0) 100%), radial-gradient(54.5% 89.5% at 79% 64%, #fbedff 0%, #ffffff 100%, #ffffff 100%)
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #FF86426b !important;
}

.ant-menu-item-icon{
  display: flex !important;
}


.discord-button {
  background-color: #5865F2 !important;
  color: white !important;
  border-color: transparent !important;
  width: 100%;
}

.discord-button:hover {
  box-shadow: 0px 0px 10px lightgray !important;
}

.discord-button:active {
  background-color: #4650c2 !important;
}

.discord-button-compact {
  background-color: #5865F2 !important;
  color: white !important;
  border-color: transparent !important;
}

.discord-button-compact:hover {
  box-shadow: 0px 0px 10px lightgray !important;
}

.discord-button-compact:active {
  background-color: #4650c2 !important;
}

.loading-overlay{
  height: 100% !important;
}

.container {
  height: 100%;
}

.vertical-center {
  width: 98%;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.crop-container{
  position: relative;
  width: 100%;
  height: 400px;
}
/* Scrollbar format */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #FF8642;
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #FF8642;
}

.noselect {
  /* non draggable */
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  /* no selectable */
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.ant-modal {
  border-radius: 12px !important;
}
.ant-modal-content {
  border-radius: 12px !important;
}
.ant-modal-header{
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
}
.ant-popover-content{
  border-radius: 12px !important;
}
.ant-popover-inner{
  border-radius: 12px !important;
}

/* force layouts to fill 100% heights */
.ant-layout-content{
  height: 100% !important;
}

.ant-tabs-content{
  height: 100% !important;
}
.ant-tabs-tabpane{
  height: 100% !important;
}

.ant-rate-star-zero > div > div.ant-rate-star-first{
  color: silver !important;
}
.ant-rate-star-zero > div > div.ant-rate-star-second{
  color: silver !important;
}

.ant-rate-star-half > div > div.ant-rate-star-second{
  color: silver !important;
}

.ant-notification-notice {
  border-radius: 12px !important;
}

.anticon.ant-notification-notice-icon-info {
  color:#5865F2 !important;
}


/* ANIMATION KEYFRAME AND CLASSES */
.horizontal-shake {
  animation: horizontal-shaking 1.5s infinite;
}
@keyframes horizontal-shaking {
  60% { transform: translateX(0)}
  70% { transform: translateX(3px) }
  80% { transform: translateX(-3px) }
  90% { transform: translateX(3px) }
  100% { transform: translateX(0) }
}


/*  BACKGROUND SVG ANIMMATION */
.svg-background {
  position: absolute;
	width: 100%;
	height: 100vh;
}
@keyframes rotate {
  100% {
      transform: rotate(360deg)
  }
}

.top-outer {
  transform-origin: 73px 69px;
  animation: rotate 30s linear infinite;
}

.top-inner {
  transform-origin: 73px 69px;
  animation: rotate 20s linear infinite;
}

.bottom-outer {
  transform-origin: 134px 222px;
  animation: rotate 30s linear infinite;
}

.bottom-inner {
        transform-origin: 134px  222px;
        animation: rotate 20s linear infinite;
}

.HubVideoPlayer video::cue { 
  font-size: 18pt;
}


.resourceSearch .ant-input-affix-wrapper {
  border-top-left-radius: 12px !important;
  border-bottom-left-radius: 12px !important;
  border: 1px #FF8642 solid !important;
}

.resourceSearch .ant-input-search-button {
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}


.react-pdf__Document{
  display: inline-block;
}

.react-pdf__Page{
  border: 1px #FF8642 solid !important;
}

.ant-layout-sider-zero-width-trigger{
  opacity: 0.8;
}