table {
  width: 100% !important;
  /* color: white !important; */
}

.ant-table table {
  border-collapse: collapse !important;
}

/* tbody {
  background-color: #1a274f;
} */

td {
  text-align: center !important;
}

th {
  text-align: center !important;
  /* color: white !important; */
}
